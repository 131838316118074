// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/auth.login/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/auth.login/route.tsx");
  import.meta.hot.lastModified = "1722566231000";
}
// REMIX HMR END

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Close, ErrorOutline } from '@mui/icons-material';
import { Checkbox, Collapse, FormControlLabel, IconButton, Typography } from '@mui/material';
import Alert from '~/components/Alert/Alert';
import { json, redirect } from '@remix-run/node';
import { Link, useActionData } from '@remix-run/react';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import APIService from '~/services/ApiService';
import { isEmail } from '~/utils/Validator';
import Logo from '../../assets/images/logo/logo.svg';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import { commitSession, getSession } from '../../sessions';
import { authSession } from '~/constants/auth';
export function ErrorBoundary({
  error
}) {
  return <div className="tw-bg-[#edede8] tw-w-full tw-pt-6">
      <ErrorDisplay message="Failed to login. Please try again." error={error} className="tw-max-w-screen-xl tw-m-auto" />
    </div>;
}
_c = ErrorBoundary;
export const loader = async ({
  request
}) => {
  const session = await getSession(request.headers.get('Cookie'));
  if (session.has('authToken')) {
    return redirect('/home');
  }
  const data = {
    error: session.get('error')
  };
  return json(data, {
    headers: {
      'Set-Cookie': await commitSession(session)
    }
  });
};
export const action = async ({
  request
}) => {
  const status = {
    type: undefined,
    message: ''
  };
  const apiService = new APIService(process.env.BASE_API_URL);
  const formData = await request.formData();
  const session = await getSession(request.headers.get('Cookie'));
  const email = String(formData.get('email'));
  const password = String(formData.get('password'));
  const rememberMe = Boolean(formData.get('rememberMe'));
  if (!email) {
    status.type = 'warning';
    status.message = 'Please enter your email';
    return json({
      status
    });
  }
  if (!isEmail(email.toLowerCase())) {
    status.type = 'warning';
    status.message = 'Please use a valid email address';
    return json({
      status
    });
  }
  try {
    const authTokenResponse = await apiService.GetToken(email, password);
    const authClaim = await apiService.AuthClaim(authTokenResponse.data['access_token']);
    session.set('authToken', authTokenResponse.data['access_token']);
    session.set('currentUser', authClaim.data['id']);
    session.set('rememberMe', rememberMe);
    const maxAge = rememberMe ? authSession['72hrs'] : authSession['24hrs'];
    return redirect('/home', {
      headers: {
        'Set-Cookie': await commitSession(session, {
          maxAge
        })
      }
    });
  } catch (error) {
    if (isAxiosError(error)) {
      session.flash('error', error.response?.data.message);
      status.type = 'error';
      status.message = error.response?.data.message;
      return json({
        status
      });
    }
  }
  status.type = 'error';
  status.message = 'Unhandled Error Occured';
  return json({
    status
  });
};
const Login = () => {
  _s();
  const actionData = useActionData();
  const [alertProps, setAlertProps] = useState({
    isShow: false,
    message: '',
    type: undefined
  });
  const [form, setForm] = useState({
    email: '',
    password: '',
    rememberMe: false
  });
  const [fieldType, setFieldType] = useState('password');
  const onChangeHandler = (e, type) => {
    const value = type === 'rememberMe' ? e.currentTarget.checked : e.currentTarget.value;
    setForm(prevState => {
      return {
        ...prevState,
        [type]: value
      };
    });
  };
  const onAlertHandler = () => {
    setAlertProps(prevState => {
      return {
        ...prevState,
        isShow: false,
        message: ''
      };
    });
  };
  const onClickIconPassword = e => {
    e.preventDefault();
    if (fieldType === 'password') {
      setFieldType('text');
    } else {
      setFieldType('password');
    }
  };
  useEffect(() => {
    const {
      message,
      type
    } = actionData?.status || {};
    setAlertProps(prevState => {
      return {
        ...prevState,
        isShow: !!message,
        message: message,
        type: type
      };
    });
  }, [actionData]);
  const {
    email,
    password,
    rememberMe
  } = form;
  const {
    isShow,
    message,
    type
  } = alertProps;
  return <div className="rounded-sm bg-white">
      <div className="flex flex-wrap items-center">
        <div className="left-side-login hidden w-full h-screen xl:w-2/3 xl:block bg-[url('/assets/images/login/login.jpg')] bg-cover bg-no-repeat">
          <div className="sample relative w-full h-full flex items-center justify-center">
            <div className="block max-w-[600px]">
              <h5 className="text-doctor font-bold text-[48px] mb-[24px]">
                Crosslink
              </h5>
              <p className="text-doctor">
                Lorem ipsum dolor sit amet consectetur. Convallis cras dolor sit
                faucibus ultrices dictum. Imperdiet posuere ac nec eu. Morbi
                phasellus venenatis eget arcu quis ipsum nulla pretium. Dapibus
                massa viverra eget sem.
              </p>
              <button type="button" className="py-[19px] px-[53px] border-[1px] rounded-[8px] mt-[48px] text-doctor">
                Read More
              </button>
            </div>
          </div>
        </div>

        <div className="right-side-login w-full h-screen relative flex flex-col items-center border-stroke xl:w-1/3 xl:border-l-2 justify-center bg-[url(/assets/images/login/crosslink-transparant.svg)] bg-cover bg-no-repeat">
          <div className="image absolute m-0 top-[60px]">
            <img src={Logo} alt="logo" width="280px" height="60px"></img>
          </div>
          <div className="container relative flex justify-center overflow-auto">
            <div className="wrapper relative block min-h-[217px] w-[452px] bg-doctor p-[50px] rounded-[8px] [box-shadow:24px_24px_48px_5px_rgba(0,_0,_0,_0.05)]">
              <h5 className="header relative block text-umbra font-bold text-[32px] text-left mb-[12px]">
                Login to your account
              </h5>
              <h6 className="subheader relative block text-left text-[16px] text-trolleygrey mb-[30px] font-['Roboto'] font-normal">
                Fill in your account details to sign in
              </h6>

              <div className="mb-6">
                <Collapse in={alertProps.isShow}>
                  <Alert variant="filled" severity={type} action={<IconButton aria-label="close" color="inherit" size="small" onClick={onAlertHandler}>
                        <Close className="!text-white" fontSize="inherit" />
                      </IconButton>}>
                    {isShow && type === 'warning' && 'There are items that require your attention'}

                    {isShow && type === 'error' && message}
                  </Alert>
                </Collapse>
              </div>

              <form method="post">
                <div className="container-email relative flex flex-col mb-[17px]">
                  <label className="label-email relative block mb-[6px] text-[16px] font-semibold text-umbra">
                    Email
                  </label>
                  <div className="input-email-container relative block w-full">
                    <input type="text" name="email" className={`input-email relative block w-full p-[12px] rounded-[4px] border-primarylight border-[1px] border-[solid] ${isShow && type === 'warning' ? 'border-[2px] border-[solid] border-rosysunset' : ''}`} placeholder="Email" onChange={e => onChangeHandler(e, 'email')} value={email}></input>
                    <i className="icon-email absolute right-0 top-2/4 -translate-y-1/2 bg-[url('/assets/images/login/email.svg')] bg-no-repeat [background-size:24px] bg-center w-[24px] h-[24px] mr-[12px]"></i>
                  </div>
                  {isShow && type === 'warning' && <div className="warning-alert relative flex items-center mt-[4px]">
                      <ErrorOutline color="error"></ErrorOutline>
                      <p className="text-message relative block text-umbra text-[14px] ml-[8px]">
                        {message}
                      </p>
                    </div>}
                </div>
                <div className="container-password relative flex flex-col">
                  <label className="label-password relative block mb-[6px] text-[16px] font-semibold text-umbra">
                    Password
                  </label>
                  <div className="input-password-container relative block w-full">
                    <input type={fieldType} name="password" className="input-password relative block w-full p-[12px] rounded-[4px] border-primarylight border-[1px] border-[solid]" placeholder="Password" onChange={e => onChangeHandler(e, 'password')} value={password}></input>
                    <button className="icon-password absolute right-0 top-2/4 -translate-y-1/2 bg-[url('/assets/images/login/password.svg')] bg-no-repeat [background-size:24px] bg-center w-[24px] h-[24px] mr-[12px]" onClick={onClickIconPassword} type="button"></button>
                  </div>
                </div>
                <button className="button-signin relative flex items-center justify-center w-full px-0 py-[14px] mt-[22px] bg-bluelight rounded-[4px] text-[14px] font-normal text-doctor mb-[36px]" type='submit'>
                  Sign In
                  <i className="button-icon bg-[url('/assets/images/login/sign-in.svg')] relative block [background-size:20px] w-[20px] h-[20px] bg-no-repeat ml-[10px]"></i>
                </button>
                <div className="container-link relative flex justify-between items-center">
                  <FormControlLabel control={<Checkbox name="rememberMe" value={rememberMe} onChange={e => onChangeHandler(e, 'rememberMe')} checked={rememberMe} />} label={<Typography variant="body1" component="p" className="text-[#828282] font-semibold">
                        Remember me
                      </Typography>} />
                  <Link className="forgot-password text-bluelight font-semibold text-[14px] font-['Roboto']" to={'/auth/recover-account'}>
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
_s(Login, "Ssam0DG87M18o66wz5faAMpaXHE=", false, function () {
  return [useActionData];
});
_c2 = Login;
export default Login;
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;